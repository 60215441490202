.academic-banner-title{
    padding: 100px 0;
}
.academic-course-details{
    background: white;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid #e2d8d8;
    text-align: justify;
}

.academic-sidebar .sidebar-widget {
    box-shadow: 0 2px 70px rgba(0, 0, 0, .1);
    border-radius: 20px;
    margin-bottom: 40px;
    background: #fff;
}

.academic-sidebar .academic-title {
    padding: 19px 22px;
}

.academic-sidebar .academic-title:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.academic-sidebar .academic-title h3 {
    font-size: 25px;
    font-weight: 600;
    color: #f9313b;
    padding: 19px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.academic-sidebar .academic-title p {
    text-align: justify;
}

.academic-content {
    border-radius: 15px;
}

.academic-content .academic-block h3 {
    font-size: 25px;
    font-weight: 600;
    color: #f9313b;
    padding: 19px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.academic-content .academic-block p a,
.academic-content .academic-block p a:hover {
    text-decoration: none;
    color: #212529;
}

/* Courses */
.course-section {
    background-color: #f4f3f8;
    position: relative;
}

.course-section .image-shape .shape-1 {
    position: absolute;
    top: 0;
    right: 0;
}

.course-block-one .course-table {
    position: relative;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    padding: 30px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.course-block-one .course-table .shape {
    position: absolute;
    left: 0;
    bottom: 0;
}

.course-block-one .course-table .table-header {
    margin-bottom: 30px;
    border-bottom: 1px solid #e5e5e5;
}

.course-block-one .course-table .table-header h3 {
    color: #f9313b;
    font-weight: 700;
    font-size: 25px;
}

.course-block-one .course-table .table-header h5 {
    color: #1d3ce2;
    font-size: 18px;
    font-weight: 600;
}

.course-block-one .course-table .table-content {
    text-align: justify;
}

.course-block-one .course-table .table-lists ul li {
    list-style-type: disclosure-closed;
    position: relative;
    z-index: 1;
}

/* Facilities Available */
.facilities {
    position: relative;
}

.facilities .image-shape .shape-1 {
    position: absolute;
    top: 0;
    right: 0;
}

.facilities-block-one .facilities-table {
    position: relative;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    padding: 20px;
}

.facilities-block-one .facilities-table .shape {
    position: absolute;
    left: 0;
    bottom: 0;
}

.facilities-block-one .facilities-table .table-header {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;
}

.facilities-block-one .facilities-table .table-header h4 {
    color: #f9313b;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
}

.facilities-block-one .facilities-table .table-content {
    text-align: justify;
}

/* DNB */
.course-title {
    margin-top: 7rem;
    border: 0;
}

.course-box {
    position: relative;
}

.course-box .nav-tabs {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    background-color: #f1f1f1;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.course-box .nav-tabs .tab_option {
    padding: 0;
}

.course-box .nav-tabs .nav-link {
    color: #212529 !important;
    font-weight: 700;
    font-size: 21px;
    padding: 10px 92.9px;
}

.course-box .tab-content {
    padding: 15px;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: auto;
}

.course-box .tab-content .fees ul li span,
.course-box .tab-content .stipend ul li span {
    font-weight: 700;
}

/* @media (max-width: 768px) {
    .course-box .nav-tabs {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .course-box .nav-tabs {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
} */